<template>
  <div class="intellectual-point-live">
    <div class="app-container">
      <div class="header-top">
        <a href="https://rapid.education/" class="logo-wrapper w-inline-block">
          <div class="logo-text">Rapid Education Live</div>
        </a>
        <div class="button-wrapper">
          <a href="#" class="button w-inline-block">
            <div class="button-gradient">
              <div class="button-modern">Exit To Home.</div>
            </div>
          </a>
        </div>
      </div>
      <div class="main-wrapper">
        <div class="join-stream-block">
          <div class="w-form">
            <div class="form">
              <div id="hubspotForm" v-once></div>
            </div>
          </div>
        </div>
        <div class="stream-preview">
          <div class="stream-title-text">Introduction to CompTIA Security+<br> First Look Bootcamp<br></div>
          <div class="stream-title-host">Intellectual Point x Rapid Education x Certification Station<br></div>
          <div class="divider"></div>
          <div class="stream-description">Thursday, May 27, 2021<br>12:00 PM to 6:00 PM EDT<br></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Presentation',
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    window.addEventListener('message', event => {

      if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
        localStorage.setItem('name', document.getElementById("firstname-c967859f-7f45-45d4-9df5-0087673594cd").value)
        localStorage.setItem('email', document.getElementById("email-c967859f-7f45-45d4-9df5-0087673594cd").value)
        this.$router.push('/watch')
      }
    });

    script.addEventListener('message', event => {
      console.log(event);
      if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
        console.log("Form Submitted!");
      }
    });


    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "8482711",
          formId: "c967859f-7f45-45d4-9df5-0087673594cd",
          target: "#hubspotForm"
        })
      }
    })
  }
};
</script>
<style>


</style>
